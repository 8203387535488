function NoPage() {
  return (
    <>
      <div className="container">
        <div className="container text-center text-danger border rounded m-4 p-4">
          <b>No Page Found</b>
        </div>
      </div>
    </>
  );
}

export default NoPage;
